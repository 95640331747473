<template>
  <base-dialog
    title="Artistas"
    btn_text="editar"
    :icon="null"
    ref="dialog"
    width="300"
    @click_open="fetchTattooers"
  >
    <v-simple-table slot="outside">
      <thead>
        <tr>
          <th>Artista</th>
          <th>¿Servicio?</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="t in tattooers" :key="t.id">
          <td width="200" class="text-left">{{ t.user.fullname }}</td>
          <td>
            <v-select
              outlined
              dense
              hide-details
              :items="yesNo"
              v-model="t.service"
            ></v-select>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <template slot="actions">
      <v-spacer></v-spacer>
      <v-btn
        outlined
        @click="
          dialog = false;
          files = [];
        "
        style="height: 25px; width: 100px"
        elevation="0"
      >
        {{ $t("cancel") }}
      </v-btn>
      <v-btn
        @click="save"
        style="
          width: 100px !important;
          color: #363533;
          height: 25px;
          padding-top: 10px !important;
        "
        elevation="0"
      >
        <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
        {{ $t("save", { name: $t("") }) }}
      </v-btn>
    </template>
  </base-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["service"],
  data() {
    return {
      tattooers: [],
    };
  },
  computed: {
    ...mapGetters("app", ["yesNo"]),
  },
  methods: {
    ...mapActions("services", ["artistsService"]),
    save() {
      let tattooers = this.tattooers.filter((t) => t.service).map((t) => t.id);
      this.artistsService({ id: this.service.id, tattooers }).then(
        (response) => {
          this.$refs.dialog.close();
          this.$emit("update");
        }
      );
    },
    ...mapActions("tattooers", ["getTattooers"]),
    fetchTattooers() {
      this.getTattooers({
        filters: {
          type_artist: this.service.artist_type_id,
          studio_id: this.$store.state.auth.user.studio.id,
        },
        pagination: { itemsPerPage: -1 },
      }).then((response) => {
        this.tattooers = response.data.map((t) => {
          let service = false;
          let u = this.service.users.find((u) => u.id == t.id);
          if (u) service = true;
          return { ...t, service };
        });
      });
    },
  },
};
</script>